
$naranja: #E89A54;
$azul: #2C4D72;
$azul2: #609EB7;
$azul3: #2C9FBA;
$azul-hover: #7DBCD3;
$azul-menu: #95A9BA;
$gris: #444544;
$gris2: #F7F7F8;

@font-face {
    font-family: newsgotic;
    src: url("../font/newsgothicstd.otf");
}
@font-face {
    font-family: newsgotic-bold;
    src: url("../font/newsgothicstd-bold.otf");
}
*{
    font-family: newsgotic;
}
p{
    color: $gris;
}
.container-fluid, .row{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

header{
    width: 100%;
    background: white;
    border-bottom: 3px solid $naranja;
    height: 100px;

    .container{
        position: relative;
        .logo{
            position: relative;
            left: 0;
            height: 100px;
        }
        .info{
            position: absolute;
            right: 0;
            top: 30px;
            ul:first-child{
                position: relative;
                right: 0;

                li{
                    display: inline;
                    a:hover{
                        img{
                            opacity: 0.3;
                        }
                    }
                }
            }
            ul:last-child{
                li{
                    display: inline;

                }
                li:first-child{
                    a{

                        color: $azul;
                    }
                }
                li:last-child{
                    color: $gris;

                }
            }
        }
    }

}

.menu{
    position: relative;
    background-image: url("../img/fondomenu.png");
    background-repeat: repeat;
    height: 60px;
    z-index: 9999;
    .navbar-collapse{
        ul{
            li{
                border-right: 1px solid white;
                a{
                    text-transform: uppercase;


                }
                a:hover{
                    color: $azul-menu;
                }
            }
        }
    }
}
.navbar-toggler {
    border-color: rgb(255,255,255);
}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .menu{
        background: white;
        .navbar-collapse{
            right: 0;
            background: white;
            ul{

                li{
                    a{
                        text-align: center;
                        color: $gris!important;
                    }
                }
            }
        }
    }
    .header-home{
        width: 100%;
        margin-top: 60px;
    }
    .carousel{
        margin-top: 0;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
    .menu {
        background: white;
        .navbar-collapse{
            right: 0;
            background: white;
            ul{

                li{
                    a{
                        text-align: center;
                        color: $gris!important;
                    }
                }
            }
        }
    }
    .header-home{
        width: 100%;
        margin-top: 60px;
    }
    .carousel{
        margin-top: 0;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    .menu {
        background: white;
        .navbar-collapse{
            right: 0;
            background: white;
            ul{

                li{
                    a{
                        text-align: center;
                        color: $gris!important;
                    }
                }
            }
        }
    }
    .header-home{
        width: 100%;
        margin-top: 60px;
    }
    .carousel{
        margin-top: 0;
    }
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
    .navbar-collapse{
        position: absolute;
        right: 300px;
        ul{

            li{
                a{
                    text-align: center;
                    color: white!important;
                }
            }
        }
    }
    .header-home{
        width: 100%;
        margin-top: -60px;
    }
    .carousel{
        margin-top: -60px;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .navbar-collapse{
        position: absolute;
        right: 300px;
        ul{

            li{
                a{
                    text-align: center;
                    color: white!important;
                }
            }
        }
    }
    .header-home{
        width: 100%;
        margin-top: -60px;
    }
    .carousel{
        margin-top: -60px;
    }
}



.carousel-caption{
    top: 30%;
    bottom: 50%;
   h5{
       text-shadow: 1px 1px 1px #000;
       font-size: 3.5em;
       font-family: newsgotic-bold;
       opacity: 0.8;
       text-transform: uppercase;

   }
}

.hub-negocios{
    .hub{
        z-index: 1000;
        margin-top: -40px;
        background: $azul;
        border: 5px solid white;
        width: 350px;
        height: 250px;
        h3{
            color: white;
            text-align: center;
            text-transform: uppercase;
            margin-top: 80px;
            margin-bottom: 90px;
        }
    }
    .text{
        width: 50%;
        p{

        }
    }
}

.lineas-lados{
    display: table;
    white-space: nowrap;
    &:before, &:after {
        border-top: 1px solid $naranja;
        content: '';
        display: table-cell;
        position: relative;
        top: 0.5em;
        width: 45%;
    }
    &:before { right: 1.5%; }
    &:after { left: 1.5%; }
}

.b{
    text-transform: uppercase;
    color: $azul;
    display: table;
    white-space: nowrap;
     &:after {
        border-top: 1px solid $naranja;
        content: '';
        display: table-cell;
        position: relative;
        top: 0.5em;
        width: 98.5%;
    }

    &:after { left: 1.5%; }
}

.home-servicios{
    h4{
        text-transform: uppercase;
        color: $azul2;
        width: 100%;
    }

    sh{

    }
    h5{
        text-align: center!important;
        width: 100%;
    }

}

.contenedor-noticia{
    .noticia{
        border-bottom: 1px solid $gris;
        .info-noticia{
            h3:first-child{
                text-transform: uppercase;
                color: $azul;
                font-family: newsgotic-bold;
            }
            h6{
                color: $gris;
            }
        }
    }
}

.ver-mas{
    font-size: 40px!important;
    text-decoration: none;


}
.ver-mas:hover{
    text-decoration: none;
    opacity: 0.8;
}



.ultimas-noticias{
    background: $gris2;
    h4{
        text-transform: uppercase;
        color: $azul;
        text-align: center;
    }

    .noticia-principal{
        h5{
            color: $azul;
        }
        h6{
            color: $naranja;
        }
    }

}
p{
    color: $gris;
}
.lista-noticias{

    .noticia-peque{
        border-bottom: 1px solid $azul-hover;
        padding-bottom: 5px;
        a{
            text-decoration: none;
            img{
                height: 98px;
                float: left;
            }
            .text{

                margin-left: 15px;
                clear: right;
                h5{
                    color: $gris;
                }
                h6{
                    color: $naranja;
                }
                p{
                    font-size: 0.9rem;

                }
            }
        }
        a:hover{
            .text{
                opacity: 0.8;
            }
        }
    }

}
.ver-noticias{
    position: absolute;
    right: 0;
    bottom: 0;
    a{
        color: $naranja;

        bottom: 0;
        right: 0;
    }
}
.mensaje{
    h5{
        color: $naranja;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 40px;
        letter-spacing: 5px;
        border-bottom: 5px solid $azul3;
        max-width: 300px;
    }

}
.container{
    .formulario{
        background: white!important;
        border-top: 6px solid $azul;
        .izq{

        }

        .der{
            form{
                .form-group{
                    margin-bottom: 10px;
                    margin-top: 10px;
                }
                .form-check-label{
                    color: grey;
                    a{
                        color: $azul;
                    }
                }
                input, textarea{
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    border-radius: 0;
                }
                button{
                    float: right;
                    background: $naranja;
                    color: white;
                    border: none;
                }
            }
        }
    }
}

.contenedor-servicios{
    .servicio{
        h5{
            text-transform: uppercase;
            color: $naranja;
            letter-spacing: 5px;
        }
    }
}

.cartagena-detalles{
    .detalle{
        h5{
            color: $naranja;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 5px;
        }
    }
}

.contenedor-agenda{
    .agenda{
        .info-agenda{
            h3:first-child{
                float: left;
                border-right: 1px solid $gris;
                span{
                    text-transform: uppercase;
                    color: $azul;

                    font-family: newsgotic-bold;
                }
                .dia{
                    font-size: 2rem;
                    text-align: center;
                }
                .mes{
                    font-size: 1rem;
                }
            }

            h3:last-child{
                text-transform: uppercase;
                color: $azul2;
                font-family: newsgotic-bold;
                font-size: 1.5rem;
                margin-left: 80px;

            }
        }
        .texto-agenda{

        }
    }
}



footer{
    background: $azul;
    .footer1{
        .fl{
            .logo{
                background: white;
            }
            .info-footer{
                ul{
                    li{
                        color: white;
                    }
                }
            }
        }
        .fr{
            background: $azul2;
            ul{
                margin-right: auto;
                margin-left: auto;
                li{
                    display: inline;
                }
            }
        }
    }
    .footer2{
        height: 60px;
        border-top: 1px solid white;
        p{
            color: white;
            a{
                color: $azul2;
            }
        }

    }
}
